import React, {useState} from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  ImageBackground,
} from 'react-native';
import {goTo} from '@utils';
import globalStore from '@/services/global.state';

interface BoxData {
  id: number;
  image: any;
  text: string;
}

const data: BoxData[] = [
  {
    id: 1,
    image: require('@/assets/icons/home/home-game-list/Lottery.webp'),
    text: 'Lottery',
  },
  {
    id: 2,
    image: require('@/assets/icons/home/home-game-list/Casino.webp'),
    text: 'Casino',
  },
  {
    id: 3,
    image: require('@/assets/icons/home/home-game-list/Live.webp'),
    text: 'Live',
  },
  {
    id: 4,
    image: require('@/assets/icons/home/home-game-list/Fishing.webp'),
    text: 'Fishing',
  },
  {
    id: 5,
    image: require('@/assets/icons/home/home-game-list/Sports.webp'),
    text: 'Sports',
  },
];

const HomeGameList: React.FC = () => {
  const [selectedBox, setSelectedBox] = useState<number>(1);

  const boxWidth = (Dimensions.get('window').width - 24) / 5; // 平分屏幕宽度

  return (
    <View style={styles.container}>
      {data.map(item => (
        <TouchableOpacity
          key={item.id}
          style={[
            styles.box,
            {width: boxWidth, height: 1.28 * boxWidth},
            selectedBox === item.id && {backgroundColor: '#ffffff'}, // 选中背景色
          ]}
          onPress={() => {
            if (!globalStore.token && item.id !== 1) {
              goTo('Login');
              return;
            }
            switch (item.id) {
              case 1:
                return;
              case 2:
                goTo('CasinoNew');
                break;
              case 3:
                goTo('CasinoLive');
                break;
              case 4:
                goTo('CasinoFishing');
                break;
              case 5:
                goTo('CasinoSports');
                break;
              default:
                break;
            }
            setSelectedBox(item.id);
          }}>
          {item.id === 1 ? ( // 第一个盒子使用背景图片
            <ImageBackground
              source={require('@/assets/icons/home/home-game-list/list-bg.webp')}
              style={styles.imageBackground}>
              <Image source={item.image} style={styles.image} />
              <Text style={styles.text}>{item.text}</Text>
            </ImageBackground>
          ) : (
            <>
              <Image source={item.image} style={styles.image} />
              <Text style={styles.text}>{item.text}</Text>
            </>
          )}
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  box: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff', // 默认背景色
  },
  imageBackground: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 60,
    height: 60,
    marginBottom: 5,
  },
  text: {
    fontSize: 13,
    fontFamily: 'Arial, Arial-Bold',
    fontWeight: '700',
    color: '#333333',
    textAlign: 'center',
  },
});

export default HomeGameList;
