import theme from '@style';
import {homeServiceStyle} from '../home.style';
import LazyImage from '@basicComponents/image';
import React, {useState} from 'react';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {View} from 'react-native';
import HomePopTwo from './home-pop-two';
import {goTo} from '@/utils';
const HomeService = ({spinShow}: {spinShow: () => void}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <View
      style={[theme.position.abs, homeServiceStyle.service, theme.flex.center]}>
      <TouchableOpacity
        onPress={() => {
          goTo('CheckIn');
        }}>
        <LazyImage
          width={80}
          height={80}
          imageUrl={require('@assets/gif/daily-tasks.gif')}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setIsVisible(!isVisible);
        }}>
        <LazyImage
          width={80}
          height={80}
          imageUrl={require('@assets/gif/first-recharge.gif')}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          spinShow();
        }}>
        <LazyImage
          width={80}
          height={80}
          imageUrl={require('@assets/gif/luckspin.gif')}
        />
      </TouchableOpacity>
      {isVisible && <HomePopTwo></HomePopTwo>}
    </View>
  );
};

export default HomeService;
