import theme from '@/style';
import {toPriceStr} from '@/utils';
import {View, ImageBackground, StyleSheet, Image} from 'react-native';
import Text from '@basicComponents/text';
import React from 'react';
import {Input} from '@rneui/themed';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useInnerStyle} from './recharge.hooks';
import {BalanceListItem} from './recharge.service';
import LinearGradient from '@/components/basic/linear-gradient';
import {useTranslation} from 'react-i18next';

export interface RechargeSelectProps {
  min: number;
  max: number;
  balance: string;
  onChangeBalance: (balance: string) => void;
  balanceList?: BalanceListItem[];
}

const RechargeSelect: React.FC<RechargeSelectProps> = ({
  balance,
  onChangeBalance,
  balanceList,
  min,
  max,
}) => {
  const {i18n} = useTranslation();
  const {inputStyle, inputStyles, selectStyles} = useInnerStyle();

  return (
    <View style={[theme.flex.col, theme.margin.topl]}>
      <View style={styleSheet.title}>
        <View style={styleSheet.titleIcon}></View>
        <Text style={styleSheet.titleText}>Deposit amount</Text>
      </View>
      <View
        style={[
          theme.padding.tops,
          theme.flex.row,
          theme.flex.wrap,
          theme.flex.between,
        ]}>
        {balanceList?.map((bl, index) => (
          <NativeTouchableOpacity
            key={index}
            style={[
              index % 3 !== 0 ? selectStyles.itemMargin : null,
              selectStyles.item,
              theme.flex.col,
            ]}
            onPress={() => onChangeBalance(bl.balance + '')}>
            {bl.balance + '' !== balance ? (
              <LinearGradient
                start={{x: 0, y: 0}}
                end={{x: 0, y: 1}}
                colors={['#e5f8f3', '#e5f8f3']}
                style={[
                  theme.flex.center,
                  theme.borderRadius.s,
                  selectStyles.item,
                ]}>
                {bl.giveBalance != 0 && (
                  <ImageBackground
                    style={[
                      {
                        width: 60,
                        height: 17.6,
                        position: 'absolute',
                        top: -8,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    ]}
                    source={require('@assets/icons/wallet/qipao.webp')}>
                    <Text
                      fontSize={theme.fontSize.xs}
                      color={theme.basicColor.white}>
                      Extra+
                      {toPriceStr(bl.giveBalance, {
                        fixed: 0,
                        showCurrency: false,
                        thousands: true,
                      })}
                      %
                    </Text>
                  </ImageBackground>
                )}

                <Text
                  fontSize={17}
                  color={theme.basicColor.primary}
                  style={[{fontWeight: '900'}]}>
                  ₹{' '}
                  {toPriceStr(bl.balance, {
                    fixed: 0,
                    showCurrency: false,
                    thousands: true,
                  })}
                </Text>
              </LinearGradient>
            ) : (
              <View
                style={[
                  theme.flex.center,
                  selectStyles.item,
                  theme.background.primary,
                  theme.borderRadius.s,
                ]}>
                {bl.giveBalance != 0 && (
                  <ImageBackground
                    style={[
                      {
                        width: 60,
                        height: 17.6,
                        position: 'absolute',
                        top: -8,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    ]}
                    source={require('@assets/icons/wallet/qipao.webp')}>
                    <Text
                      fontSize={theme.fontSize.xs}
                      color={theme.basicColor.white}>
                      Extra+{' '}
                      {toPriceStr(bl.giveBalance, {
                        fixed: 0,
                        showCurrency: false,
                        thousands: true,
                      })}
                      %
                    </Text>
                  </ImageBackground>
                )}

                <Text
                  fontSize={17}
                  color={theme.basicColor.white}
                  style={[{fontWeight: '900'}]}>
                  ₹{' '}
                  {toPriceStr(bl.balance, {
                    fixed: 0,
                    showCurrency: false,
                    thousands: true,
                  })}
                </Text>
              </View>
            )}
          </NativeTouchableOpacity>
        ))}
      </View>
      <View style={[theme.flex.col, theme.margin.btms]}>
        <View
          style={[
            theme.flex.col,
            theme.background.lightGrey,
            selectStyles.inputWrap,
            theme.borderRadius.xs,
            theme.margin.btms,
          ]}>
          <Input
            containerStyle={[theme.padding.lrm, inputStyles.container]}
            inputContainerStyle={inputStyles.inputContainer}
            style={inputStyle}
            errorStyle={inputStyles.error}
            keyboardType="numeric"
            inputMode="numeric"
            value={balance}
            onChangeText={value => {
              if (value && !value.startsWith('0')) {
                if (/^[0-9]+$/.test(value)) {
                  if (max > 0) {
                    if (Number(value) <= max) {
                      onChangeBalance(value);
                    }
                  } else {
                    if (Number(value) <= 50000) {
                      onChangeBalance(value);
                    }
                  }
                } else {
                  onChangeBalance(balance || '');
                }
              } else {
                onChangeBalance('');
              }
            }}
            placeholder={i18n.t('recharge-page.label.enter')}
          />
        </View>
        <View style={styleSheet.tips}>
          <Image
            source={require('@/assets/icons/wallet/recharge-tishi.webp')}
            style={styleSheet.tipsImg}
          />
          <Text style={styleSheet.tipsText}>
            {i18n.t('recharge-page.label.min')}
          </Text>
          <Text style={[theme.margin.leftxxs, styleSheet.tipsText]}>
            {min ? toPriceStr(min, {fixed: 0, thousands: true}) : '--'}
          </Text>
          <Text style={[theme.margin.leftxxl, styleSheet.tipsText]}>
            {i18n.t('recharge-page.label.max')}
          </Text>
          <Text style={[theme.margin.leftxxs, styleSheet.tipsText]}>
            {max ? toPriceStr(max, {fixed: 0, thousands: true}) : '--'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styleSheet = StyleSheet.create({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  titleIcon: {
    width: 4,
    height: 15,
    backgroundColor: '#00bb8f',
    borderRadius: 2,
    marginRight: 8,
  },
  titleText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#333333',
    fontFamily: 'Arial, Arial-Bold',
  },
  tips: {
    ...theme.flex.row,
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
  },
  tipsImg: {
    ...theme.margin.rightxs,
    width: 14,
    height: 14,
  },
  tipsText: {fontSize: 12, color: '#999999'},
});

export default RechargeSelect;
