/* eslint-disable */
/* prettier-ignore */
import globalStore from '@/services/global.state';
import { http } from '@utils';

export const sendCode = (userPhone: string) => {
  return http.post('app/sendCode', { userPhone });
};

export const userLogin = (
  userPhone: string,
  code: string,
  deviceCode: string,
  inviteCode: string,
  equipmentType: string,
  systemType: string,
  userInviteCode?: string,
  isLogin: boolean = true,
) => {
  const date = {
    userPhone,
    code,
    deviceCode,
    inviteCode,
    equipmentType,
    systemType,
    userInviteCode,
  };
  if (!isLogin && globalStore.isWeb && !userInviteCode) {
    date.userInviteCode = localStorage.getItem('invitationCode') || undefined;
  }
  return http.post<
    null,
    {
      token: string;
      isNewUser: string;
    }
  >('app/userLoginNew', date);
};

export const passwordLogin = (userPhone: string, password: string, deviceCode: string, inviteCode: string, equipmentType: string, systemType: string) => {
  return http.post<null, string>('app/password/login', {
    userPhone,
    password,
    deviceCode,
    inviteCode,
    equipmentType,
    systemType
  });
};

export const updatePassword = (password: string) => {
  return http.post<null, string>('app/user/set/password', {
    password,
  });
};
