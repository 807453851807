import React from 'react';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@style';
import {PayMethod} from './recharge.service';
import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import Ok from '../svg/ok';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';

export interface RechargeChannelProps {
  payMethodList: PayMethod[];
  payMethodId?: number;
  onPayMethodChange: (id: number) => void;
}

const RechargeChannel: React.FC<RechargeChannelProps> = ({
  payMethodList,
  payMethodId,
  onPayMethodChange,
}) => {
  const {i18n} = useTranslation();
  const {payMethodStyles} = useInnerStyle();
  return (
    <View style={[theme.flex.col, {marginBottom: 30}]}>
      <View style={styleSheet.title}>
        <View style={styleSheet.titleIcon}></View>
        <Text style={styleSheet.titleText}>
          {' '}
          {i18n.t('recharge-page.label.channel')}
        </Text>
      </View>
      <View style={[theme.flex.col, theme.flex.wrap]}>
        {payMethodList.map((payMethod, index) => {
          return (
            <NativeTouchableOpacity
              key={index}
              onPress={() => onPayMethodChange(payMethod.id)}>
              <View
                key={index}
                style={[
                  payMethodStyles.item,
                  theme.flex.row,
                  theme.position.rel,
                  theme.flex.centerByCol,
                  theme.padding.lrl,
                  {
                    marginTop: 10,
                    borderWidth: 2,
                    borderColor:
                      payMethod.id === payMethodId ? '#00bb8f' : '#eeeeee',
                    borderStyle: 'solid',
                    borderRadius: 10,
                    backgroundColor:
                      payMethod.id === payMethodId ? '#e5f8f4' : '#ffffff',
                  },
                ]}>
                <View style={[theme.flex.center, theme.margin.rightl]}>
                  <LazyImage
                    occupancy="#0000"
                    imageUrl={payMethod.payIcon}
                    width={theme.iconSize.xxl}
                    height={theme.iconSize.xxl}
                  />
                </View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[payMethodStyles.itemText, theme.font.center]}>
                  {payMethod.payName}
                </Text>
                {payMethod.id === payMethodId ? (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.itemSelectedIcon,
                      theme.flex.center,
                      {marginRight: 12},
                    ]}>
                    <Ok />
                  </View>
                ) : (
                  // 空圆圈不要了，占位
                  <View></View>
                  // <View
                  //   style={[
                  //     theme.position.abs,
                  //     payMethodStyles.itemNoSelectedIcon,
                  //     theme.flex.center,
                  //     {marginRight: 12},
                  //   ]}></View>
                )}
              </View>
            </NativeTouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styleSheet = StyleSheet.create({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  titleIcon: {
    width: 4,
    height: 15,
    backgroundColor: '#00bb8f',
    borderRadius: 2,
    marginRight: 8,
  },
  titleText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#333333',
    fontFamily: 'Arial, Arial-Bold',
  },
});

export default RechargeChannel;
