import {View, StyleSheet, Modal, TouchableOpacity, Image} from 'react-native';
import Text from '@/components/basic/text';
import React, {useState} from 'react';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import BankListItem from './component/bank-list-item';
import {useTranslation} from 'react-i18next';
import {CardListItemType} from './withdraw-service';
const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');
const addIcon = require('@assets/icons/withdraw/add.webp');
const sampleImage = require('@assets/icons/withdraw/rule.webp'); // 弹出图片路径
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface WithdrawBankProps {
  onAddBank?: () => void;
  onSelectBank?: () => void;
  bankInfo?: CardListItemType;
}

const WithdrawBank = (props: WithdrawBankProps) => {
  const {screenWidth} = useScreenSize();
  const {i18n} = useTranslation();
  const {bankInfo = {}, onAddBank = () => {}, onSelectBank = () => {}} = props;
  // 新增的 state 用于控制弹出图片的显示
  const [isImageVisible, setIsImageVisible] = useState(false);
  // 显示图片
  const showImage = () => {
    setIsImageVisible(true);
  };

  // 关闭图片
  const closeImage = () => {
    setIsImageVisible(false);
  };
  return (
    <View style={[theme.margin.topl, theme.padding.lrl]}>
      <View
        style={[
          theme.flex.flex,
          theme.flex.row,
          theme.flex.between,
          {marginBottom: 10},
        ]}>
        <Text main size="medium">
          {i18n.t('withdraw-page.label.transferTo')}
        </Text>
        <Text color={'#999999'} size="medium" onPress={showImage}>
          {i18n.t('withdraw-page.label.showImage')}
        </Text>
      </View>
      {bankInfo!.id ? (
        <BankListItem
          cardInfo={bankInfo}
          onItemPress={onSelectBank}
          rightIcon={
            <LazyImage
              occupancy={'transparent'}
              imageUrl={rightIcon}
              width={14}
              height={14}
            />
          }
        />
      ) : (
        <NoBank onAddBank={onAddBank} />
      )}
      {/* 弹出的图片 Modal */}
      <Modal
        transparent={true}
        visible={isImageVisible}
        onRequestClose={closeImage}
        animationType="fade">
        <TouchableOpacity
          style={styles.modalOverlay}
          activeOpacity={1}
          onPressOut={() => {}}>
          <LazyImage
            imageUrl={sampleImage}
            width={screenWidth / 1.05}
            height={screenWidth / 0.72}
          />
          <TouchableOpacity onPress={closeImage} style={styles.closeButton}>
            <Image
              style={[theme.icon.xxl]}
              source={require('@assets/icons/home/button-close.png')}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const NoBank = ({onAddBank}: {onAddBank: () => void}) => {
  const {i18n} = useTranslation();
  return (
    <NativeTouchableOpacity
      onPress={onAddBank}
      style={[
        theme.borderRadius.m,
        theme.flex.center,
        theme.padding.l,
        styles.noBankContainer,
        theme.background.white,
      ]}>
      <View
        style={[
          theme.fill.fill,
          theme.borderRadius.m,
          theme.flex.center,
          theme.padding.l,
          styles.noBankContainer,
        ]}>
        <LazyImage
          occupancy={'transparent'}
          imageUrl={addIcon}
          width={24}
          height={24}
        />
        <Text main fontFamily={'fontDinBold'} style={[theme.margin.tops]}>
          {i18n.t('withdraw-page.label.addBank')}
        </Text>
      </View>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  noBankContainer: {
    backgroundColor: '#F6F7FA',
    maxHeight: 120,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    marginTop: 20,
    backgroundColor: 'none',
  },
});

export default WithdrawBank;
