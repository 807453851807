import Text from '@basicComponents/text';
import React from 'react';
import {View, Image} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@services/global.state';
import Tag from '@basicComponents/tag';
import {homeDrawerStyle} from '../home.style';
import {checkToken, goTo, scaleSize} from '@/utils';
import {useLanguageModal} from '@/components/business/language';
import {useTranslation} from 'react-i18next';
import {languagesMap} from '@/i18n';
import LinearGradient from '@basicComponents/linear-gradient';

const HomeMenu = (props: {
  setMenuOpen: (bool: boolean) => void;
  unreadCount?: number;
}) => {
  const {setMenuOpen, unreadCount} = props;
  const {i18n} = useTranslation();
  const {renderModal, show} = useLanguageModal();
  return (
    <View
      style={[
        theme.fill.fillW,
        theme.flex.col,
        theme.padding.l,
        {
          height: globalStore.screenHeight,
        },
      ]}>
      <View
        style={[
          theme.padding.tbl,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByRow,
        ]}>
        <Image
          style={[homeDrawerStyle.logo]}
          source={require('@assets/icons/logo-horizontal.webp')}
        />
        {/* <Text
          fontSize={24}
          style={[theme.margin.leftl, theme.font.center]}
          color={theme.fontColor.primary}
          blod>
          Suplottery
        </Text> */}
        <View
          style={[theme.flex.flex1, theme.flex.row, theme.flex.centerByRow]}>
          <View style={[theme.flex.flex1]} />
          <NativeTouchableOpacity onPress={() => setMenuOpen(false)}>
            <Image
              style={[homeDrawerStyle.itemIcon]}
              source={require('@assets/icons/close.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('Invitation');
          }
        }}
        style={[theme.position.rel, {marginBottom: 20}]}>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          colors={theme.backgroundColor.chestLinearGradient}
          style={[
            theme.position.abs,
            theme.fill.fillW,
            theme.borderRadius.m,
            {
              height: scaleSize(50),
              bottom: 0,
              left: 0,
              zIndex: 1,
            },
          ]}>
          <View
            style={[
              theme.flex.centerByRow,
              theme.flex.flex1,
              {marginLeft: scaleSize(98)},
            ]}>
            <Text main>Refer And Earn</Text>
            <Text main blod>
              WIN More
            </Text>
          </View>
        </LinearGradient>
        <Image
          style={[
            {
              width: scaleSize(74),
              height: scaleSize(74),
              zIndex: 2,
              marginLeft: scaleSize(12),
            },
          ]}
          source={require('@assets/icons/home/home-menu/chest_icon.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>
      {[
        {
          icon: require('@assets/icons/home/home-menu/lottery.webp'),
          label: 'lottery',
          navTo: 'HomeDetail',
          navParams: {detailType: 'kerala'},
        },
        {
          icon: require('@assets/icons/home/home-menu/live.webp'),
          label: 'liveCasino',
          navTo: 'Live',
        },
        {
          icon: require('@assets/icons/home/home-menu/casino.webp'),
          label: 'casino',
          navTo: 'Casino',
        },
        // {
        //   icon: require('@assets/icons/home/home-menu/sports.webp'),
        //   label: 'sports',
        //   navTo: 'Sports',
        //   navParams: {detailType: 'kerala'},
        // },
        // TODO 促销活动
        // {
        //   icon: require('@assets/icons/home/promotions.webp'),
        //   label: 'promotions',
        // },
      ].map((v, i) => (
        <NativeTouchableOpacity
          key={i}
          onPress={() => {
            if (v.navTo) {
              setTimeout(() => {
                setMenuOpen(false);
              }, 16);
              goTo(v.navTo, v.navParams);
            } else {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('warning.unopen'),
              });
            }
          }}
          style={[
            theme.background.primary10,
            theme.padding.l,
            theme.borderRadius.m,
            {
              marginBottom: theme.paddingSize.xxs / 2,
            },
          ]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
              source={v.icon}
            />
            <Text fontSize={theme.fontSize.m}>
              {i18n.t('home.tab.' + v.label)}
            </Text>
          </View>
        </NativeTouchableOpacity>
      ))}
      {/* 消息模块 */}
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('NotifyNew');
          }
        }}
        style={[
          theme.background.primary10,
          theme.padding.l,
          theme.margin.topxxl,
          theme.borderRadius.m,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/home-menu/notifications.webp')}
          />
          <Text fontSize={theme.fontSize.m}>{i18n.t('me.bottom.notify')}</Text>
          <View style={theme.flex.flex1} />
          {unreadCount != null && unreadCount > 0 && (
            <Tag content={unreadCount} />
          )}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          theme.background.primary10,
          theme.padding.l,
          theme.margin.topxxl,
          theme.borderRadius.m,
        ]}
        onPress={() => show()}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/home-menu/earth.webp')}
          />
          <Text fontSize={theme.fontSize.m}>
            {languagesMap[globalStore.lang]}
          </Text>
          <View style={theme.flex.flex1} />
          <Image
            style={[homeDrawerStyle.down]}
            source={require('@assets/icons/down.webp')}
          />
        </View>
      </NativeTouchableOpacity>
      {renderModal}
    </View>
  );
};

export default HomeMenu;
