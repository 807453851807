import React, {useState} from 'react';
import {Image, Modal, TouchableOpacity, StyleSheet} from 'react-native'; //Text
import theme from '@style';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import globalStore from '@/services/global.state';
import {goTo} from '@utils';

const sampleImage = require('@assets/icons/home/first-recharge.webp'); // 弹出图片路径
const HomePop = () => {
  const {screenWidth} = useScreenSize();
  // 新增的 state 用于控制弹出图片的显示
  const [isImageVisible, setIsImageVisible] = useState(true);
  // 关闭图片
  const closeImage = () => {
    setIsImageVisible(false);
    globalStore.isNewUser = 'false';
  };
  // 点击图片
  const onImage = () => {
    closeImage();
  };

  return (
    <Modal
      transparent={true}
      visible={isImageVisible}
      onRequestClose={closeImage}
      animationType="fade">
      <TouchableOpacity
        style={styles.modalOverlay}
        activeOpacity={1}
        onPressOut={() => {
          goTo('Recharge');
        }}
        onPress={onImage}>
        <LazyImage
          imageUrl={sampleImage}
          width={screenWidth * 0.89}
          height={screenWidth * 1.28}
        />
        <TouchableOpacity onPress={closeImage} style={styles.closeButton}>
          <Image
            style={[theme.icon.xxl]}
            source={require('@assets/icons/home/button-close.png')}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  noBankContainer: {
    backgroundColor: '#F6F7FA',
    maxHeight: 120,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    marginTop: 20,
    backgroundColor: 'none',
  },
});

export default HomePop;
