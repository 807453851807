import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack, goTo} from '@/utils';
import React, {useEffect, useMemo, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {
  BalanceListItem,
  PayMethod,
  getBalanceList,
  getPayMethod,
  goIncome,
  paySuccess,
} from './recharge.service';
import Spin from '@/components/basic/spin';
import {Success, upiPayment} from '@/utils';
import RechargeBalance from './recharge-balance';
import RechargeSelect from './recharge-select';
import RechargeChannel from './recharge-channel';
import RechargeRule from './recharge-rule';
import RechargeButton from '@/components/business/recharge-button';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
// import {LazyImageLGBackground} from '@/components/basic/image';

const Recharge = () => {
  const {i18n} = useTranslation();
  const [balanceList, setBalanceList] = React.useState<BalanceListItem[]>([]);
  const [paymethodList, setPaymenthodList] = React.useState<PayMethod[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState('');
  const [payMethodId, setPayMethodId] = React.useState<number>();

  const payMethodItem = useMemo(() => {
    return paymethodList.find(p => p.id === payMethodId);
  }, [paymethodList, payMethodId]);
  const balanceId = useMemo(() => {
    const item = balanceList.find(b => b.balance === +balance);
    return item ? item.id + '' : '';
  }, [balanceList, balance]);
  const [amount, setAmount] = useState<number>(0);
  useEffect(() => {
    const sub = globalStore.amountChanged.subscribe(res => {
      setAmount(res.current);
      setLoading(false);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  const [incomeInfo, setIncomeInfo] = React.useState({
    upiId: '',
    orderNo: '',
  });
  React.useEffect(() => {
    setLoading(true);
    Promise.all([getBalanceList(), getPayMethod()])
      .then(([blance, paymenthod]) => {
        setBalanceList(blance);
        setBalance((blance[0]?.balance || '') + '');
        setPaymenthodList(paymenthod);
        setPayMethodId(paymenthod[0].id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleRefresh = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    setLoading(true);
    globalStore.updateAmount.next();
  };

  const handleGotoRecords = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    goTo('RechargeRecords');
  };

  // 支付成功回调
  const onSuccess = (success: Success) => {
    if (success.status === 'SUCCESS') {
      paySuccess({
        orderNo: incomeInfo.orderNo,
        tradeResult: '1',
        approvalUrt: success.approvalRefNo,
      })
        .then()
        .finally(() => {
          globalStore.updateAmount.next();
        });
    } else {
      // crashlytics().recordError(
      //   new Error(
      //     JSON.stringify({
      //       type: '支付成功,但状态不是success',
      //       incomeInfo: incomeInfo,
      //       successCB: success,
      //     }),
      //   ),
      // );
    }
  };
  const onFailure = (error: Error) => {
    globalStore.globalWaringTotal(error.message);
  };

  const handleRecharge = async () => {
    const {minAmount, maxAmount} = payMethodItem || {};
    if (
      !balance ||
      +balance <= 0 ||
      (minAmount && +balance < minAmount) ||
      (maxAmount && +balance > maxAmount)
    ) {
      globalStore.globalWaringTotal(i18n.t('recharge-page.tip.money-error'));
      return;
    }
    if (!payMethodItem) {
      globalStore.globalWaringTotal(
        i18n.t('recharge-page.tip.paymethod-error'),
      );
      return;
    }
    setLoading(true);
    goIncome({
      balanceId: balanceId || 0,
      payTag: payMethodItem.payTag,
      payTypeId: payMethodId + '',
      rechargeBalance: balanceId ? 0 : balance,
    })
      .then(res => {
        if (globalStore.channel === 'hipfc01') {
          const url = `https://ppprfd.pgoriginad.com/action/3b982489-5c0b-484c-991e-b3fe72720144/131093?value=${balance}`;
          fetch(url);
        }
        if (typeof res === 'string') {
          if (globalStore.isWeb) {
            location.href = res;
          } else {
            goTo('WebView', {
              originUrl: res,
              header: true,
              headerTitle: i18n.t('label.recharge'),
              serverRight: false,
              hideAmount: true,
            });
          }
        } else {
          setIncomeInfo(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // 吊起APP支付
  const onPay = () => {
    const config = {
      payeeVpa: incomeInfo.upiId,
      payeeName: incomeInfo.upiId,
      merchantCode: incomeInfo.upiId,
      transactionId: incomeInfo.orderNo,
      transactionRefId: incomeInfo.orderNo,
      description: incomeInfo.orderNo,
      amount: balance + '',
    };
    upiPayment.initiate('net.one97.paytm', '', config, onSuccess, onFailure);
  };

  const bBalance: number = Number(balance);
  let exResult: number = 0;
  const sortedBalanceList = [...balanceList].sort(
    (a, b) => a.balance - b.balance,
  );
  for (let i = 0; i < sortedBalanceList.length - 1; i++) {
    if (
      sortedBalanceList[i].balance <= bBalance &&
      bBalance < sortedBalanceList[i + 1].balance
    ) {
      exResult = (sortedBalanceList[i].giveBalance / 100) * bBalance;
      break; // 找到后退出循环
    }

    // 在循环内直接处理大于或等于最大值的情况
    if (
      i === sortedBalanceList.length - 2 &&
      bBalance >= sortedBalanceList[i + 1].balance
    ) {
      const lastItem = sortedBalanceList[i + 1];
      exResult = (lastItem.giveBalance / 100) * bBalance;
      break;
    }
  }

  React.useEffect(() => {
    if (incomeInfo.orderNo && incomeInfo.upiId) {
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeInfo]);

  return (
    <View
      style={[theme.fill.fill, theme.flex.col, {backgroundColor: '#f6f6f6'}]}>
      <DetailNavTitle
        onBack={goBack}
        hideServer
        hideAmount
        titleColor={theme.fontColor.main}
        title={i18n.t('label.recharge')}
        iconColor={'black'}
        containerStyle={[theme.background.white]}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <ScrollView>
            <View style={[{backgroundColor: '#ddf0eb'}]}>
              <RechargeBalance
                balance={amount}
                payMethod={payMethodItem?.payName}
                onRefresh={handleRefresh}
                onGotoRecords={handleGotoRecords}
              />
            </View>
            <View style={[theme.fill.fillH, {backgroundColor: '#f6f6f6'}]}>
              <View style={[theme.padding.lrxxl, {backgroundColor: '#ffffff'}]}>
                <RechargeSelect
                  min={payMethodItem?.minAmount || 0}
                  max={payMethodItem?.maxAmount || 0}
                  balance={balance}
                  balanceList={balanceList}
                  onChangeBalance={setBalance}
                />
                <RechargeChannel
                  payMethodList={paymethodList}
                  onPayMethodChange={setPayMethodId}
                  payMethodId={payMethodId}
                />
              </View>
              <View style={[theme.padding.lrxxl]}>
                <RechargeRule />
              </View>
            </View>
          </ScrollView>
        </View>
        <RechargeButton
          disabled={balance === '' || +balance <= 0}
          onRecharge={handleRecharge}
          text={
            i18n.t('label.recharge') +
            (exResult > 0
              ? ` (Extra +₹${exResult.toFixed(2).toString()} )`
              : '')
          }
        />
      </Spin>
    </View>
  );
};

export default Recharge;
