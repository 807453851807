import React from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';
import globalStore from '@/services/global.state';

const HipPage = () => {
  return (
    <View style={styles.container}>
      <View style={styles.line}></View>
      <View style={styles.hipBox}>
        <View style={styles.hipLeft}>
          <Text style={styles.titleOne}>Gaming license</Text>
          <Image
            style={styles.leftImage}
            source={require('@assets/imgs/home/Gaming.webp')}
          />
          <Text style={styles.leftText}>
            Regulated & Licensed by the Government of Curacao and operates under
            the Master License of Gaming Services Provider.N.V.#365/JAZ
          </Text>
        </View>
        <View style={styles.hipRight}>
          <Text style={styles.titleOne}>Responsible Gaming</Text>
          <View style={styles.rightBox}>
            <Image
              style={styles.rightImage}
              source={require('@assets/imgs/home/Aware.webp')}
            />
            <Text style={styles.rightText}>Be Gamble Aware</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
  },
  line: {
    width: globalStore.screenWidth - 24,
    height: 1,
    backgroundColor: '#000000',
  },
  hipBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  titleOne: {
    fontSize: 14,
    color: '#00000',
    fontWeight: '500',
  },
  hipLeft: {
    width: globalStore.screenWidth * 0.56,
  },
  leftImage: {
    width: globalStore.screenWidth * 0.33,
    height: globalStore.screenWidth * 0.12,
    marginTop: 8,
    marginBottom: 8,
  },
  leftText: {
    fontSize: 14,
    color: '#00000',
    fontWeight: '400',
    opacity: 0.8,
  },
  hipRight: {},
  rightBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 8,
  },
  rightImage: {
    width: globalStore.screenWidth * 0.08,
    height: globalStore.screenWidth * 0.08,
  },
  rightText: {
    fontSize: 14,
    color: '#00000',
    fontWeight: '500',
    opacity: 0.8,
    width: 50,
    marginLeft: 12,
  },
});

export default HipPage;
