import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import {goTo} from '@utils';
import LinearGradient from '@/components/basic/linear-gradient';

// 获取屏幕宽度
const screenWidth = Dimensions.get('window').width;

const HomeHotGame: React.FC = () => {
  return (
    <View style={styles.container}>
      {/* 顶部图片 */}
      <LinearGradient colors={['#ccf1e9', '#ffffff']} style={styles.topLinear}>
        <ImageBackground
          source={require('@/assets/icons/home/home-hot-game/hot-header.webp')}
          style={styles.topImage}>
          <Image
            source={require('@/assets/icons/home/home-hot-game/hot.gif')}
            style={styles.topImageHot}></Image>
          <Text style={styles.topImageText}>Hot Game</Text>
        </ImageBackground>
      </LinearGradient>

      {/* 三个盒子 */}
      <View style={styles.boxContainer}>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'dice', params: `configId=16`});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-hot-game/hot-dice.webp')}
            style={styles.box}>
            <Text style={styles.boxText}>Dice</Text>
            <Text style={styles.boxTextTwo}>1-5min</Text>
          </ImageBackground>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'color'});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-hot-game/hot-color.webp')}
            style={styles.box}>
            <Text style={styles.boxText}>Color</Text>
            <Text style={styles.boxTextTwo}>1-5min</Text>
          </ImageBackground>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            goTo('GameWebView', {type: 'quick3d', params: 'id=103'});
          }}>
          <ImageBackground
            source={require('@/assets/icons/home/home-hot-game/hot-quick.webp')}
            style={styles.box}>
            <Text style={styles.boxText}>Quick 3D</Text>
            <Text style={styles.boxTextTwo}>1-5min</Text>
          </ImageBackground>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 14,
  },
  boxContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -14,
    paddingHorizontal: 14,
  },
  box: {
    width: (screenWidth - 40) / 3, // 减去左右边距后均分宽度
    height: ((screenWidth - 40) / 3) * 0.63,
    overflow: 'hidden',
  },
  boxText: {
    fontSize: 15,
    fontFamily: 'Arial Black, Arial Black-Black',
    color: '#ffffff',
    fontWeight: '900',
    position: 'absolute',
    top: 10,
    left: 10,
  },
  boxTextTwo: {
    fontSize: 12,
    fontFamily: 'Arial, Arial-Regula',
    color: '#ffffff',
    fontWeight: '400',
    position: 'absolute',
    top: 32,
    left: 10,
  },
  topLinear: {
    width: screenWidth - 12,
    height: 48,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  topImage: {
    width: 91,
    height: 26,
    marginTop: -6,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginHorizontal: 'auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topImageHot: {
    width: 21,
    height: 24,
    marginBottom: 8,
  },
  topImageText: {
    fontSize: 12,
    fontWeight: '700',
    color: '#00bb8f',
    fontFamily: 'Arial, Arial-Bold',
  },
});

export default HomeHotGame;
