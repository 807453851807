import theme from '@/style';
import {View, StyleSheet} from 'react-native';
import Button from '@/components/basic/button';
import Text from '@/components/basic/text';
import React from 'react';
import {ButtonType} from '@/components/basic/button/button';
import {useTranslation} from 'react-i18next';
import {useResponsiveDimensions} from '@/utils';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface RechargeButtonProps {
  disabled?: boolean;
  type?: ButtonType;
  text?: string;
  onRecharge?: () => void;
}

const RechargeButton: React.FC<RechargeButtonProps> = ({
  type = 'primary',
  onRecharge,
  disabled,
  text,
}) => {
  const {i18n} = useTranslation();
  const {width} = useResponsiveDimensions();
  const {designWidth} = useScreenSize();
  const rechargeButtonWidth = (335 * width) / designWidth;
  const rechargeStyle = StyleSheet.create({
    button: {
      width: rechargeButtonWidth,
      height: 0.128 * designWidth,
    },
    buttonWrap: {
      backgroundColor: '#ffffff',
      paddingTop: theme.paddingSize.l,
      paddingBottom: theme.paddingSize.l,
    },
  });

  return (
    <View
      style={[
        theme.flex.center,
        theme.fill.fillW,
        rechargeStyle.buttonWrap,
        // theme.background.lightGrey,
      ]}>
      <Button
        size="large"
        type={type}
        radius={5}
        disabled={disabled}
        width={rechargeButtonWidth}
        buttonStyle={[rechargeStyle.button]}
        onPress={onRecharge}>
        <Text fontSize={theme.fontSize.m} blod color={theme.basicColor.white}>
          {text || i18n.t('label.recharge')}
        </Text>
      </Button>
    </View>
  );
};

export default RechargeButton;
