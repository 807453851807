import React from 'react';
import theme from '@style';
import {View, Text, StyleSheet} from 'react-native';

const CasinoNewPage = () => {
  return (
    <View style={styleSheet.rule}>
      <Text style={styleSheet.ruleTitle}>Deposit instructions</Text>
      <View style={[theme.flex.col, theme.flex.wrap]}>
        <Text style={styleSheet.ruleText}>
          1, If the transfer time is up, please fill out the deposit form again.
        </Text>
        <Text style={styleSheet.ruleText}>
          2, Please do not use save UPI and save QR Code to recharge. Each
          recharge starts a new order.
        </Text>
        <Text style={styleSheet.ruleText}>
          3, The transfer amount must match the order you created, otherwise the
          money cannot be credited successfully.
        </Text>
        <Text style={styleSheet.ruleText}>
          4, If you transfer the wrong amount, our company will not be
          responsible for the lost amount!
        </Text>
        <Text style={styleSheet.ruleText}>
          5, Note: do not cancel the deposit order after the money has been
          transferred.
        </Text>
        <Text style={styleSheet.ruleText}>
          6, Users are requested to download Paytm UPI App for faster recharge.
        </Text>
        <Text style={styleSheet.ruleTextRed}>
          7, Due to the normal risk alerts caused by bank control, please feel
          free to recharge.
        </Text>
        <Text style={styleSheet.ruleTextRed}>
          8, If the funds are not received in time, please contact the online
          customer service for assistance.
        </Text>
      </View>
    </View>
  );
};

const styleSheet = StyleSheet.create({
  rule: {
    marginTop: 20,
  },
  ruleTitle: {
    color: '#666666',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 22,
  },
  ruleText: {
    color: '#999999',
    fontSize: 12,
    lineHeight: 22,
  },
  ruleTextRed: {
    color: '#dc362e',
    fontSize: 12,
    lineHeight: 22,
  },
});

export default CasinoNewPage;
